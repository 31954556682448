import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 用户评价 - 分页查询
 * @param params
 */
export function getGroundRunList(params) {
  return request({
    url: envInfo.bgApp.customerWXPath + '/order/queryCustomerApplyList',
    method: 'get',
    params,
  });
}

/**
 * 用户评价 - 导出
 * @param params
 */
export function evalutionListExport(params) {
  return request({
    url: envInfo.bgApp.customerWXPath + '/order/export',
    method: 'get',
    params,
  });
}

/**
 * 用户评价 - 上传通行证
 * @param params
 */
export function uploadPassport(params) {
  return request({
    url: envInfo.bgApp.customerWXPath + '/order/upPassCard',
    method: 'post',
    data: params,
  });
}
