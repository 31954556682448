<template>
    <div class="ground-component">
      <div v-if="pageType == 1">
        <div class="detail-name">申请时间</div>
        <div class="detail-content">
          {{ dataset.creationDate | dateFormat("YYYY-MM-DD HH:mm:ss") }}
        </div>
        <div class="detail-name">申请人</div>
        <div class="detail-content">{{ dataset.ordPersonName }}</div>
        <div class="detail-name">订单编号</div>
        <div class="detail-content">{{ dataset.orderCode }}</div>
        <div class="detail-name">手机号码</div>
        <div class="detail-content">{{ dataset.ordPersonPhone }}</div>
        <div class="detail-name">申请单位</div>
        <div class="detail-content">{{ dataset.enterpriseDescName }}</div>
        <!-- <div v-if="dataset.groundDrivingVO">
          <div class="detail-name">车辆停放位置</div>
          <div class="detail-content">
            {{ dataset.groundDrivingVO.vehiclePosition }}
          </div>
          <div class="detail-name">车辆入口</div>
          <div class="detail-content">
            {{ dataset.groundDrivingVO.vehicleEntrance }}
          </div>
        </div> -->
  
        <div class="detail-name">工单状态</div>
        <div class="detail-content">{{ dataset.orderStatusStr }}</div>
        <div class="detail-name">订单状态</div>
        <div class="detail-content">{{ dataset.customerOrderStatusStr }}</div>
        <!-- <div v-if="dataset.vehicleVOList.length > 0">
          <div class="detail-name">搬运材料信息</div>
          <div
            v-for="(it, index) in dataset.vehicleVOList"
            :key="index"
            class="detail-car"
          >
            <span> 货长（m）:{{ it.goodsLength }} </span
            ><span> 货宽（m）:{{ it.goodsWidth }} </span>
            <span> 货高（m）:{{ it.goodsHeight }} </span
            ><span> 货重（kg）:{{ it.goodsWeight }} </span>
          </div>
        </div> -->
        <div v-if="dataset.resourceVOList.length > 0">
          <div class="detail-name">上传图片</div>
          <div class="detail-imgList">
            <div v-for="(it, index) in dataset.resourceVOList" :key="index">
              <el-image
                style="width: 64px; height: 64px; border-radius: 6px"
                :src="imgPath + it.fileId"
                :preview-src-list="previewList"
              >
              </el-image>
            </div>
          </div>
        </div>
  
        <div v-if="dataset.passCard">
          <div class="detail-name">通行证上传人</div>
          <div class="detail-content">{{ dataset.upPassCardBy }}</div>
          <div class="detail-name">通行证上传时间</div>
          <div class="detail-content">
            {{ dataset.upPassCardTime | dateFormat("YYYY-MM-DD HH:mm:ss") }}
          </div>
          <div class="detail-name">通行证</div>
          <div class="detail-imgList">
            <el-image
              style="width: 64px; height: 64px; border-radius: 6px"
              :src="imgPath + dataset.passCard"
              :preview-src-list="passPortList"
            >
            </el-image>
          </div>
        </div>
        <div v-if="dataset.reasonList.length > 0">
          <div class="detail-name">驳回记录</div>
          <div class="detail-reason">
            <div
              v-for="(it, index) in dataset.reasonList"
              :key="index"
              class="reason-border"
            >
              <div class="reason-title reason-list">
                {{ dataset.reasonList.length - index }}：{{ it.explainCard }}
              </div>
              <div class="reason-list">操作人：{{ it.userName }}</div>
              <div class="reason-list">
                驳回时间：{{
                  it.creationDate | dateFormat("YYYY-MM-DD HH:mm:ss")
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="pageType == 2">
        <div class="upload-container" v-loading="uploadLoading">
          <el-upload
            v-if="!imageUrl"
            class="avatar-uploader"
            :action="uploadPath"
            :with-credentials="true"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
  
          <el-image
            v-if="imageUrl"
            class="avatar"
            :src="imageUrl"
            :preview-src-list="upLoadPreview"
          >
          </el-image>
          <div v-show="fileId" class="delete-icon" @click="deleteHandler">
            <i class="el-icon-delete"></i>
          </div>
        </div>
        <div class="page-button">
          <el-button type="primary" @click="upLoadImg()">确定</el-button>
          <el-button @click="resetForm()">取消</el-button>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { envInfo } from "@/constants/envInfo";
  import { uploadPassport } from "@/api/ruge/bms/customerService/freightElevators";
  export default {
    props: {
      dataset: {
        type: Object,
        default() {
          return {};
        },
      },
      pageType: {
        type: Number,
        default: "",
      },
    },
    data() {
      return {
        imgPath:
          envInfo.bgApp.archivePath +
          "/param/archive/download?dlType=DefaultDownload&fType=image&fi=",
        imageUrl: "",
        uploadPath:
          envInfo.bgApp.archivePath +
          "/param/archive/upload?ulType=DefaultUpload&scopeType=ALL",
        photoIdListId: [],
        fileId: "",
        dialogVisible: false,
        dialogImageUrl: "",
        previewList: [],
        passPortList: [],
        upLoadPreview: [],
        uploadLoading: false,
      };
    },
    created() {
      console.log("pageType", this.pageType);
      // if (this.dataset.resourceVOList.length > 0) {
      //   this.dataset.resourceVOList.forEach((it) => {
      //     this.previewList.push(this.imgPath + it.fileId);
      //   });
      // }
      if (this.dataset.passCard) {
        this.passPortList.push(this.imgPath + this.dataset.passCard);
      }
    },
    methods: {
      upLoadImg() {
        if (!this.fileId) {
          this.$message({
            message: "请先选择通行证！",
            type: "info",
          });
          return;
        }
        uploadPassport({
          orderId: this.dataset.orderId,
          passCard: this.fileId,
        }).then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$message.success("上传成功");
            this.$emit("close", true);
          } else {
            this.$message.success("上传失败");
          }
        });
      },
      resetForm() {
        this.$emit("close", false);
      },
      handleAvatarSuccess(files) {
        this.photoIdListId = [files[0].fileId];
        this.fileId = files[0].fileId;
        this.imageUrl = this.imgPath + files[0].fileId;
        this.upLoadPreview[0] = this.imgPath + files[0].fileId;
        this.uploadLoading = false;
        console.log("files", files, this.imageUrl);
      },
      beforeAvatarUpload() {
        this.uploadLoading = true;
      },
      deleteHandler() {
        this.photoIdListId = [];
        this.imageUrl = null;
      },
      handlePictureCardPreview() {
        this.dialogImageUrl = this.imageUrl;
        this.dialogVisible = true;
      },
    },
  };
  </script>
  <style lang="less" scoped>
  .ground-component {
    .detail-name {
      font-size: 14px;
      color: #999;
      height: 24px;
      line-height: 24px;
    }
    .detail-content {
      font-size: 14px;
      color: #000;
      height: 24px;
      line-height: 24px;
      margin-bottom: 10px;
    }
    .detail-num {
      font-size: 14px;
      color: #409eff;
      height: 24px;
      line-height: 24px;
      margin-bottom: 10px;
    }
    .detail-imgList {
      display: flex;
      img {
        width: 80px;
        height: 80px;
        margin-right: 24px;
      }
    }
    .detail-car {
      margin-top: 6px;
      span {
        font-size: 12px;
        color: black;
        margin-right: 6px;
      }
    }
    .detail-reason {
      margin-top: 6px;
      .reason-border {
        margin-bottom: 16px;
        .reason-list {
          height: 20px;
          font-size: 12px;
          color: #6b7278;
          font-family: Noto Sans SC-Regular, Noto Sans SC;
          font-weight: 400;
          line-height: 20px;
        }
        .reason-title {
          font-size: 14px;
          color: black;
        }
      }
    }
  
    .upload-container {
      display: flex;
      position: relative;
      .delete-icon {
        position: absolute;
        left: 186px;
        bottom: 0;
        font-size: 14px;
        color: #ff0000;
        cursor: pointer;
      }
    }
    .avatar-uploader {
      border: 1px dotted #ccc;
      height: 178px;
      width: 178px;
    }
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
    .page-button {
      margin-top: 24px;
      text-align: right;
    }
  }
  </style>